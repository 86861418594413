import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { OtpComponent } from './views/otp/otp.component';


import { AuthGuard } from './auth/auth.guard';

export const routes: Routes = [
  { path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full' },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'otp',
    component: OtpComponent,
    data: {
      title: 'Otp Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    }, canActivate: [AuthGuard], runGuardsAndResolvers: 'always',

    children: [
      {
        path: 'add-edit-company',
        loadChildren: () => import('./views/add-edit-company/add-edit-company.module').then(m => m.AddEditCompanyModule)
      },
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'cadm-dashboard',
        loadChildren: () => import('./views/cadm-dashboard/cadm-dashboard.module').then(m => m.CadmDashboardModule)
      },
      {
        path: 'cc-enquiry-update',
        loadChildren: () => import('./views/cc-enquiry-update/cc-enquiry-update.module').then(m => m.CcEnquiryUpdateModule)
      },
      {
        path: 'consignments',
        loadChildren: () => import('./views/consignments/consignments.module').then(m => m.ConsignmentsModule)
      },
      {
        path: 'create-gsx-repair',
        loadChildren: () => import('./views/create-gsx-repair/create-gsx-repair.module').then(m => m.CreateGsxRepairModule)
      },
     {
        path: 'update-repair',
        loadChildren: () => import('./views/update-repair/update-repair.module').then(m => m.UpdateRepairModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'onsite-analytics',
        loadChildren: () => import('./views/analytics/analytics.module').then(m => m.AnalyticsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'dashboard/:type',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'editors',
        loadChildren: () => import('./views/editors/editors.module').then(m => m.EditorsModule)
      },
      {
        path: 'forms',
        loadChildren: () => import('./views/forms/forms.module').then(m => m.FormsModule)
      },
      {
        path: 'google-maps',
        loadChildren: () => import('./views/google-maps/google-maps.module').then(m => m.GoogleMapsModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'new-ticket',
        loadChildren: () => import('./views/new-ticket/new-ticket.module').then(m => m.NewTicketModule)
      },
      {
        path: 'search-dashboard',
        loadChildren: () => import('./views/search-dashboard/search-dashboard.module').then(m => m.SearchDashboardModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'plugins',
        loadChildren: () => import('./views/plugins/plugins.module').then(m => m.PluginsModule)
      },
      {
        path: 'tables',
        loadChildren: () => import('./views/tables/tables.module').then(m => m.TablesModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'apps',
        loadChildren: () => import('./views/apps/apps.module').then(m => m.AppsModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'authentication',
        loadChildren: () => import('./views/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'relocate-engg',
        loadChildren: () => import('./views/relocate-engg/relocate-engg.module').then(m => m.RelocateEnggModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('./views/analytics/analytics.module').then(m => m.AnalyticsModule)
      },
      {
        path: 'kbb-outward',
        loadChildren: () => import('./views/kbb-outward/kbb-outward.module').then(m => m.KbbOutwardModule)
      },
      {
        path: 'kbb-outward/kbbform',
        loadChildren: () => import('./views/kbb-outward/kbb-form/kbb-form.module').then(m => m.KbbFormModule)
      },
      {
        path: 'kgb-inward',
        loadChildren: () => import('./views/kgb-inward/kgb-inward.module').then(m => m.KgbInwardModule)
      },
      {
        path: 'change-ticket-status',
        loadChildren: () => import('./views/change-ticket-status/change-ticket-status.module').then(m => m.ChangeTicketStatusModule)
      },
      {
        path: 'part-add-edit',
        loadChildren: () => import('./views/part-add-edit/part-add-edit.module').then(m => m.PartAddEditModule)
      },
      {
        path: 'pud-service',
        loadChildren: () => import('./views/pud-service/pud-service.module').then(m => m.PudServiceModule)
      },
      {
        path: 'technician-dashboard',
        loadChildren: () => import('./views/analytics/technician-dashboard/technician-dashboard.module').then(m => m.TechnicianDashboardModule)
      },
      {
        path: 'repair-approval',
        loadChildren: () => import('./views/repair-approval/repair-approval.module').then(m => m.RepairApprovalModule)
      },
      {
        path: 'free-phy-location',
        loadChildren: () => import('./views/free-phy-location/free-phy-location.module').then(m => m.FreePhyLocationModule)
      },
      {
        path: 'user-create',
        loadChildren: () => import('./views/user-create/user-create.module').then(m => m.UserCreateModule)
      },
      {
        path: 'ticket-details-edit',
        loadChildren: () => import('./views/ticket-details-edit/ticket-details-edit.module').then(m => m.TicketDetailsEditModule)
      },
     {
        path: 'customer-care',
        loadChildren: () => import('./views/customer-care/customer-care.module').then(m => m.CustomerCareModule)
      },
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
