import { Component, OnDestroy, Inject, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// import { navItems } from './../../_nav';
import { Router } from '@angular/router';
import { UserService } from '../.././shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as _ from 'lodash';

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: any;
  title?: boolean;
  children?: any;
  variant?: string;
  attributes?: object;
  divider?: boolean;
  class?: string;
}

export interface ConfirmAlert {
  id: any;
  title: any;
  msg: any;
}

export const navItems: NavData[] = [];
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})

export class DefaultLayoutComponent implements OnDestroy {
  // public navItems = navItems;
  error;
  isError = '';
  public navItems = '';
  public menus: any = '';
  public menuItems: any = [];
  public filteredMenus: any = '';
  public userName = '';
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public tasks: any = [];
  timer;
  notifications: any = [];
  notificationList: any = [];
  notifiCount;
  messageReadFlag = true;
  messageBoardText = '';
  messageList: any = [];
  messageDate: any;
  messageBoardBtn = 'Done';
  locations: any = [];
  statuses: any = [];
  products: any = [];
  technician = '';
  userGroup: any;
  display = 'none';
  displaysms = 'none';
  displayOpen = 'none';
  unreadMessages: any = [];
  allSMS: any = [];
  messageTypes = [
    {label: 'All Users', value: 'A'},
    {label: 'Group Users', value: 'G'},
    {label: 'Single/Multiple Users', value: 'SM'},
  ];
  messageType = '';
  messageTypesTemp: any = [];
  isGroup = false;
  isUsers = false;
  userList: any = [];
  userListTemp: any = [];
  userGroupList: any = [];
  groupId = '';
  userGroupTemp: any = [];
  toOneUser = '';
  toMultipleUser: any = [];
  toMultipleTemp: any = [];
  messageText = '';
  ticketId = '';
  openUnreadMessage: any = [];
  modalRef: BsModalRef;
  confirmAlert: ConfirmAlert = {id: '', title: '', msg: ''};
  isMultipleAccess = localStorage.getItem('mutipleAccess');
  siteType = localStorage.getItem('siteType');
  toType = '';
  statuseList: any = [];
  constructor(private userService: UserService, private router: Router, private modalService: BsModalService,
    @Inject(DOCUMENT) _document?: any) {
    this.userGroup = localStorage.getItem('userGroup');
    this.getWidget();
    this.getMenu();
    this.requestNotification();
    this.getSMSMessages();
    this.getAllSMS();
    this.timer = setInterval(() => {
        this.requestNotification();
        this.getSMSMessages();
        this.getAllSMS();
    }, 1500000);

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  this.getTasks();
  this.readMessage();
  this.getAllMessages();
  this.userName = localStorage.getItem('userName');
  localStorage.setItem('locations', this.locations);
  localStorage.setItem('statuses', this.statuses);
  localStorage.setItem('products', this.products);
  localStorage.setItem('technician', this.technician);
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  switchType(confirm_alert_temp: TemplateRef<any>) {
    let type = '';
    if (this.siteType === '1') {
      type = 'Onsite';
      this.toType = '2';
    } else if (this.siteType === '2') {
      type = 'Service Center';
      this.toType = '1';
    }
    const msg = 'Are you sure want to Switch to ' + type;
    this.confirmAlert = {id: 'switch_type', title: 'Switch Service Type', msg: msg};
    this.openModal(confirm_alert_temp);
  }

  confirm(inputdata) {
    let result: any;
    if (inputdata === 'switch_type') {
      this.userService.switchType(this.toType)
        .subscribe(
          (data) => {
            result = data;
            if (result.status === true) {
              this.signOut();
            } else {
              alert(result.message);
            }
          }, // success path
        error => this.error = error // error path
      );
      this.modalRef.hide();
    }
  }

  openModal(templat) {
    this.modalRef = this.modalService.show(templat, { backdrop: 'static', keyboard: false });
  }

  cancelModel() {
    this.modalRef.hide();
  }

  signOut() {
     this.userService.logoutGSX().subscribe((data: any) => {
          // console.log(data);
      },
      (error: HttpErrorResponse) => {
          console.log(HttpErrorResponse);
      });
      clearInterval(this.timer);
      const rooturl = localStorage.getItem('rootUrl');
      localStorage.clear();
      localStorage.setItem('rootUrl', rooturl);
      this.router.navigate(['login']);
   }

   getWidget() {
     let widgets: any = [];
     let widgetItems: any = [];
     const results1: any = [];
     let children: any = [];
      const data1 = 'user_id=' + localStorage.getItem('userId') + '&user_role=' + localStorage.getItem('userRole');
      this.userService.getWidget(data1).subscribe((data: any) => {
        this.navItems = Array.of(data.json.widget)[0];
        widgets = Array(data.json.widget)[0];
        widgetItems = Array(data.json.widget_items)[0];
        this.statuseList = Array(data.json.status)[0];
          for (let i = 0; i < widgetItems.length; i++) {
          }
          for (let j = 0; j < widgetItems.length; j++) {
            children = [];
            for (let k = 0; k < this.statuseList.length; k++) {
              if (this.statuseList[k].widget_item_name === widgetItems[j].widget_item_name) {
                children.push({
                  name: this.statuseList[k].status_name + '~' + this.statuseList[k].count,
                  icon: 'cui-wrench',
                  url: widgetItems[j].angular_url + '/' + this.statuseList[k].status_id
                  // url: widgetItems[j].angular_url + '/' + 'mytickets'
                });
              }
            }
            results1.push({
              name: widgetItems[j].widget_item_name,
              url: widgetItems[j].angular_url,
              icon: widgetItems[j].widget_item_icon,
              widget_name: widgetItems[j].widget_name,
              children: children
            });
          }
        this.menus = results1;
        },
        (error: HttpErrorResponse) => {alert(error.message);
      });
   }

   getMenu() {
    const data1 = 'user_id=' + localStorage.getItem('userId') + '&user_role=' + localStorage.getItem('userRole');
    this.userService.getMenu(data1).subscribe((data: any) => {
      this.menuItems = Array.of(data)[0];
      },
      (error: HttpErrorResponse) => {alert(error.message);
    });
    setTimeout(() => {
      this.selectedWidget('Pre-Repair');
    }, 2000);
   }

   selectedWidget(widgetName) {
    this.filteredMenus = _.filter(this.menus, row => row.widget_name.indexOf(widgetName) > -1);
    this.filteredMenus = this.filteredMenus.concat(this.menuItems);
    /*this.filteredMenus.push({
      Common: this.menuItems
    }); */
   }

   menuClick(event) {
    const status = event.target.textContent.split('~');
    for (let i = 0; i < this.statuseList.length; i++) {
      if (this.statuseList[i].status_name === status[0]) {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        });
      }
    }
   }

  getTasks() {
    const data1 = 'group=' + localStorage.getItem('userRole') + '&user_id=' + localStorage.getItem('userId') + '&X_API_KEY=' +
                localStorage.getItem('userToken');
    this.userService.getTasks(data1).subscribe((data: any) => {
      this.tasks = data;
      },
      (error: HttpErrorResponse) => {alert(error.message);
    });
   }

   getAllMessages() {
    const data1 = 'group=' + localStorage.getItem('userRole') + '&user_id=' + localStorage.getItem('userId') + '&X_API_KEY=' +
    localStorage.getItem('userToken');
    let result: any;
    this.userService.getAllMessageBoard(data1).subscribe((data: any) => {
      result = data;
      if (result.status === true) {
        this.messageList = result.rows;
      }

    },
    (error: HttpErrorResponse) => {alert(error.message);
    });
   }

  readMessage() {
  const data1 = 'group=' + localStorage.getItem('userRole') + '&user_id=' + localStorage.getItem('userId') + '&X_API_KEY=' +
                localStorage.getItem('userToken');
  this.userService.getMessageBoard(data1).subscribe((data: any) => {
      if (data.message_read_flag === 'Y') {
        this.display = 'none';
      } else {
        this.display = 'block';
        this.messageBoardBtn = 'Read and Understood';
        this.messageBoardText = data['row'].text;
        this.messageDate = data['row'].entry_time;
      }
    },
      (error: HttpErrorResponse) => {alert(error.message);
    });
  }

  setMessageFlag() {
      const data1 = 'group=' + localStorage.getItem('userRole') + '&user_id=' + localStorage.getItem('userId') + '&X_API_KEY=' +
                  localStorage.getItem('userToken');
                  let result;
      this.userService.setMessageFlag(data1).subscribe((data: any) => {
        result = data;
        if (result.status === true) {
          this.display = 'none';
        }
      },
        (error: HttpErrorResponse) => {alert(error.message);
      });
  }

  openMessage(msgid) {
    this.messageBoardBtn = 'Done';
    this.messageReadFlag = true;
    for (let i = 0; i < this.messageList.length; i++) {
      if (msgid === this.messageList[i].id) {
        this.messageBoardText = this.messageList[i].text;
      }
    }
    this.display = 'block';
  }

  closeBoard() {
    this.display = 'none';
    this.displaysms = 'none';
    this.displayOpen = 'none';
    this.userGroupList = [];
    this.messageText = '';
    this.ticketId = '';
    this.isError = '';
    this.groupId = '';
    this.userGroupTemp = [];
    this.toMultipleTemp = [];
    this.messageTypesTemp = [];
  }

  requestNotification() {
    let result: any = [];
    const data = 'user_id=' + localStorage.getItem('userId') + '&group_id=' + localStorage.getItem('userRole') +
                '&X_API_KEY=' + localStorage.getItem('userToken');
    this.userService.getNotifications(data).subscribe((res: any) => {
        result = res;
        if (result.status === true) {
            this.notifications = result.notification;
            this.notifiCount = this.notifications.length;
        }
    });
  }

  getAllSMS() {
    let result;
    this.userService.getAllSMS()
      .subscribe(
        (data) => {
            result = data;
            if (result.status === true) {
              this.allSMS = result.message;
              // this.allSMS = this.allSMS.reverse();
            } else {
              this.allSMS = [];
            }
        }, // success path
        error => this.error = error // error path
      );
  }

  getSMSMessages() {
    let result;
    this.userService.getSMSMessages()
      .subscribe(
        (data) => {
            result = data;
            if (result.status === true) {
              this.unreadMessages = result.message;
            } else {
              this.unreadMessages = [];
            }
        }, // success path
        error => this.error = error // error path
      );
  }

  openSMSMessage(messageId, type) {
    if (type === 'U') {
      for (let i = 0; i < this.unreadMessages.length; i ++) {
        if (messageId === this.unreadMessages[i].id) {
          this.openUnreadMessage = this.unreadMessages[i];
          this.displayOpen = 'block';
          let result;
          this.userService.updateMessage(messageId)
          .subscribe(
            (data) => {
                result = data;
                if (result.status === true) {
                  this.getSMSMessages();
                  this.getAllSMS();
                }
            }, // success path
            error => this.error = error // error path
          );
        }
      }
    } else {
        for (let i = 0; i < this.allSMS.length; i ++) {
          if (messageId === this.allSMS[i].id) {
            this.openUnreadMessage = this.allSMS[i];
            this.displayOpen = 'block';
          }
      }
    }
  }

  getOptions() {
    let result;
    this.userService.getOptions()
      .subscribe(
        (data) => {
            result = data;
            this.userGroupList = result.role;
        }, // success path
        error => this.error = error // error path
      );
  }

  gerUserList() {
    let result;
    this.userService.getUserList()
      .subscribe(
        (data) => {
            result = data;
            this.userList = result;
        }, // success path
        error => this.error = error // error path
      );
  }


  sendSMS() {
    this.getOptions();
    this.gerUserList();
    setTimeout(() => {
      this.displaysms = 'block';
    }, 2000);
  }

  selectMessageType(event) {
    this.messageTypesTemp = event;
    this.messageType = event.value;
    if (this.messageType === 'G') {
      this.isGroup = true;
      this.isUsers = false;
    } else if (this.messageType === 'SM') {
      this.isGroup = false;
      this.isUsers = true;
    } else {
      this.isGroup = false;
      this.isUsers = false;
    }
  }

  selectUser(event) {
    this.toMultipleTemp = event;
    this.toMultipleUser.push(event);
  }

  selectUserGroup(event) {
    this.userGroupTemp = event;
    this.groupId = event.id;
  }

  send() {
    if ((this.ticketId === '') || (this.messageType === '') || (this.messageText === '')) {
      this.isError = 'Fill all fields';
    } else {
      if (this.messageType === 'G') {
        if (this.groupId === '') {
          this.isError = 'Fill all fields';
        } else {
          this.isError = '';
        }
      } else if (this.messageType === 'SM') {
        if (this.toMultipleUser.length === 0) {
          this.isError = 'Fill all fields';
        } else {
          this.isError = '';
        }
      }
    }
    setTimeout(() => {
      if (this.isError === '') {
        this.callSMS();
      }
    }, 1000);
  }

  callSMS() {
    if (this.messageType === 'A') {
      const inputData = '&type=' + this.messageType + '&text=' + this.messageText + '&ticket_id=' + this.ticketId;
      let result;
      this.userService.sendMessage(inputData)
        .subscribe(
          (data) => {
              result = data;
              if (result.status === true) {
                this.closeBoard();
              }
          }, // success path
          error => this.error = error // error path
        );
    } else if (this.messageType === 'G') {
      const inputData = '&type=' + this.messageType + '&text=' + this.messageText + '&ticket_id=' + this.ticketId +
      '&group_id=' + this.groupId;
      let result;
      this.userService.sendMessage(inputData)
        .subscribe(
          (data) => {
              result = data;
              if (result.status === true) {
                this.closeBoard();
              }
          }, // success path
          error => this.error = error // error path
        );
    } else if (this.messageType === 'SM') {
      let oneUser = '';
      let multipleUser: any = [];
      if (this.toMultipleUser.length === 1) {
        this.messageType = 'S';
        oneUser = this.toMultipleUser[0].value;
        const inputData = '&type=' + this.messageType + '&text=' + this.messageText + '&ticket_id=' + this.ticketId +
        '&to_user=' + oneUser;
        let result;
        this.userService.sendMessage(inputData)
          .subscribe(
            (data) => {
                result = data;
                if (result.status === true) {
                  this.closeBoard();
                }
            }, // success path
            error => this.error = error // error path
          );
      } else {
        this.messageType = 'M';
        multipleUser = JSON.stringify(this.toMultipleUser);
        const inputData = '&type=' + this.messageType + '&text=' + this.messageText + '&ticket_id=' + this.ticketId +
        '&to_user=' + multipleUser;
        let result;
        this.userService.sendMessage(inputData)
          .subscribe(
            (data) => {
                result = data;
                if (result.status === true) {
                  this.closeBoard();
                }
            }, // success path
            error => this.error = error // error path
          );
      }
    }
  }
}
