import {Component, OnInit} from '@angular/core';
import {UserService} from '../.././shared/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
// import { FormsModule }   from '@angular/forms';


import {FormGroup, FormBuilder, Validators} from '@angular/forms';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    constructor(private userService: UserService, private router: Router, private fb: FormBuilder) {
    localStorage.setItem('rootUrl', 'http://myaccount.icareservice.co.in/crm3/');
    // localStorage.setItem('rootUrl', 'http://myaccount.icareservice.co.in/crm3test/');
    // localStorage.setItem('rootUrl', 'http://api.icareservice.co.in/');
    }

    formErrors = {
        'userName': '',
        'password': ''
    };

    validationMessages = {
        'userName': {
            'required': 'User Name is required.'

        },
        'password': {
            'required': 'Password is required.',
            'minlength': 'User Name must be greater than 2 characters.',
            'maxlength': 'Password must be less than 20 characters.',
        },
    };

    // get f() {return this.simpleForm.controls;}

    ngOnInit() {
        this.loginForm = this.fb.group({
            userName: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]]
            /* password: ['', [Validators.required, Validators.minLength(this.vf.formRules.passwordMin),
            Validators.pattern(this.vf.formRules.passwordPattern)]] */
        });

        this.loginForm.valueChanges.subscribe((data) => {
            this.logValidationErrors(this.loginForm);
        });

    }

    logValidationErrors(group: FormGroup = this.loginForm): void {
        Object.keys(group.controls).forEach((key: string) => {
            const abstractControl = group.get(key);
            if (abstractControl instanceof FormGroup) {
                this.logValidationErrors(abstractControl);
            } else {
                this.formErrors[key] = '';
                if (abstractControl && !abstractControl.valid
                    && (abstractControl.touched || abstractControl.dirty)) {
                    const messages = this.validationMessages[key];
                    for (const errorKey in abstractControl.errors) {
                        if (errorKey) {
                            this.formErrors[key] += messages[errorKey] + ' ';
                        }
                    }
                }
            }
        });
    }

    OnSubmit(userName, password) {
        this.userService.userAuthentication(userName, password).subscribe((data: any) => {
            if (data.user.user_id) {
                if (data.user.double_auth_enabled === 'Y') {
                  localStorage.setItem('userToken', data.user.key);
                  localStorage.setItem('userId', data.user.user_id);
                  localStorage.setItem('isGsxUser', data.user.gsx_user);
                  this.router.navigate(['otp'], { queryParams: { mobile: userName} });
                  /* if (data.gsx_user === true) {
                    this.userService.authenticateGSX().subscribe((data: any) => {
                      localStorage.setItem('gsxAuth', data.status);
                    },
                    (error: HttpErrorResponse) => {
                        console.log(HttpErrorResponse);
                    });
                  } */
                } else {
                  // this.router.navigate(['otp'], { queryParams: { mobile: userName} });
                    localStorage.setItem('isGsxUser', data.user.gsx_user);
                    localStorage.setItem('userToken', data.user.key);
                    localStorage.setItem('userId', data.user.user_id);
                    localStorage.setItem('userRole', data.user.group_id);
                    localStorage.setItem('userGroup', data.user.user_group);
                    localStorage.setItem('userName', data.user.user_name);
                    localStorage.setItem('UserMobile', data.user.mobile);
                    localStorage.setItem('siteType', data.user.site_type_id);
                    localStorage.setItem('mutipleAccess', data.user.mutiple_site_access);
                    /* this.userService.authenticateGSX().subscribe((data: any) => {
                      localStorage.setItem('gsxAuth', data.status);
                    },
                    (error: HttpErrorResponse) => {
                        console.log(HttpErrorResponse);
                    }); */
                   this.router.navigate([data.user.default_page]);
                }
            } else {
                alert('Invalid Credentails');
                this.router.navigate(['login']);
            }
        },
            (error: HttpErrorResponse) => {
                alert('Invalid Credentails');
                // this.isLoginError = true;
            });
    }
}
