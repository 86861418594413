import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { User } from './user.model';

@Injectable()
export class UserService {
  rootUrl = localStorage.getItem('rootUrl');
  constructor(private http: HttpClient) {
  }

  registerUser(user: User) {
    const body: User = {
      UserName: user.UserName,
      Password: user.Password,
      Email: user.Email,
      FirstName: user.FirstName,
      LastName: user.LastName
    };

  const reqHeader = new HttpHeaders({'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'user/', body, {headers : reqHeader});
  }

  userAuthentication(userName, password) { // alert(userName);
    const data = 'mobile=' + userName + '&password=' + password + '&grant_type=password';
    // var data = JSON.stringify({mobile: '9747312347', password: 'abc'}); //alert(data);
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'user/flogin', data, {headers : reqHeader});
  }

  getMenu(data) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
      return this.http.post(this.rootUrl + 'user/user/getmenu', data , {headers : reqHeader});
  }

  getWidget(data) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'user/user/getwidget', data , {headers : reqHeader});
  }

  getMenuPermission(groupID, subMenu) {
    const data = 'group=' + groupID + '&submenu=' + subMenu + '&user_id=' + localStorage.getItem('userId') +
    '&X_API_KEY=' + localStorage.getItem('userToken');
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'user/get_menu_permission', data , {headers : reqHeader});
  }

  getWidgetPermission(groupID, statusId) {
    const data = 'X_API_KEY=' + localStorage.getItem('userToken') + '&status_id=' + statusId + '&group_id=' + groupID;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'user/get_widget_permission', data , {headers : reqHeader});
  }

  getUser(userId) {
    const data = 'user_id=' + userId;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'user/get_user', data , {headers : reqHeader});
  }

  setUser(userId, user) {
      const data = 'user_id=' + userId + '&data=' + user + '&X_API_KEY=' + localStorage.getItem('userToken');
      const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
      return this.http.post(this.rootUrl + 'api/common/update_user', data , {headers : reqHeader});
  }
  getTasks(data) {
     /// var data = "group=" + groupID+"user_id=" + userId+ "&data=" + user+'&X_API_KEY=' + localStorage.getItem('userToken');
      const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
      return this.http.post(this.rootUrl + 'api/common/get_tasks', data , {headers : reqHeader});
  }

  getNotifications(data) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'api/tickets/get_notifications', data , {headers : reqHeader});
  }

  resetPassword(userId, user) {
    const data = 'user_id=' + userId + '&data=' + user + '&X_API_KEY=' + localStorage.getItem('userToken');
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'api/common/reset_password', data , {headers : reqHeader});
  }

 userOtpAuthentication(mobile, otp) { // alert(userName);
    const data = 'mobile=' + mobile + '&otp=' + otp + '&grant_type=password';
   const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'user/otplogin', data, {headers : reqHeader});
  }

  authenticateGSX(key) {
    let form;
    if (key === '') {
      form = 'user_id=' + localStorage.getItem('userId') + '&X_API_KEY=' + localStorage.getItem('userToken');
    } else {
      form = 'user_id=' + localStorage.getItem('userId') + '&X_API_KEY=' + localStorage.getItem('userToken') + '&gsx_api_key=' + key;
    }
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'api/gsxapi/get_auth_token', form, {headers : reqHeader});
  }

  mapCrmGsx(ticketId) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    const form = 'X_API_KEY=' + localStorage.getItem('userToken') + '&user_id=' + localStorage.getItem('userId') + '&ticket_id=' + ticketId;
    return this.http.post(this.rootUrl + 'api/gsxapi/map_gsx_crm_repair_status', form, {headers : reqHeader});
  }

  mapBlueDartTrack(ticketId) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    const form = 'X_API_KEY=' + localStorage.getItem('userToken') + '&user_id=' + localStorage.getItem('userId') + '&ticket_id=' + ticketId;
    return this.http.post(this.rootUrl + 'api/gsxbatchapi/repair_shipping_tracking_auto', form, {headers : reqHeader});
  }

  logoutGSX() {
    const form = 'X_API_KEY=' + localStorage.getItem('userToken') + '&user_id=' + localStorage.getItem('userId');
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'api/gsxapi/authenticate_end_session', form, {headers : reqHeader});
  }

  getMessageBoard(data) {
    /// var data = "group=" + groupID+"user_id=" + userId+ "&data=" + user+'&X_API_KEY=' + localStorage.getItem('userToken');
     const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
     return this.http.post(this.rootUrl + 'api/common/get_message_board', data , {headers : reqHeader});
  }

  getAllMessageBoard(data) {
    /// var data = "group=" + groupID+"user_id=" + userId+ "&data=" + user+'&X_API_KEY=' + localStorage.getItem('userToken');
     const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
     return this.http.post(this.rootUrl + 'api/common/get_all_message', data , {headers : reqHeader});
  }

  getSMSMessages() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    const form = 'X_API_KEY=' + localStorage.getItem('userToken') + '&user_id=' + localStorage.getItem('userId') + '&status=' + 'U';
    return this.http.post(this.rootUrl + 'api/message/get_message', form, {headers : reqHeader});
  }

  getAllSMS() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    const form = 'X_API_KEY=' + localStorage.getItem('userToken') + '&user_id=' + localStorage.getItem('userId') + '&status=' + 'R';
    return this.http.post(this.rootUrl + 'api/message/get_message', form, {headers : reqHeader});
  }

  setMessageFlag(data) {
    /// var data = "group=" + groupID+"user_id=" + userId+ "&data=" + user+'&X_API_KEY=' + localStorage.getItem('userToken');
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + 'api/common/set_message_flag', data , {headers : reqHeader});
  }

  getOptions() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    const form = 'X_API_KEY=' + localStorage.getItem('userToken') + '&user_id=' + localStorage.getItem('userId');
    return this.http.post(this.rootUrl + 'api/common/get_user_details', form, {headers : reqHeader});
  }

  getUserList() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    const form = 'X_API_KEY=' + localStorage.getItem('userToken');
    return this.http.post(this.rootUrl + 'api/message/get_users', form, {headers : reqHeader});
  }

  sendMessage(inputData) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    const form = 'X_API_KEY=' + localStorage.getItem('userToken') + '&from_user=' + localStorage.getItem('userId') + inputData;
    return this.http.post(this.rootUrl + 'api/message/save_message', form, {headers : reqHeader});
  }

  updateMessage(messageId) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    const form = 'X_API_KEY=' + localStorage.getItem('userToken') + '&from_user=' + localStorage.getItem('userId') + '&id=' + messageId;
    return this.http.post(this.rootUrl + 'api/message/update_status', form, {headers : reqHeader});
  }

  switchType(siteType) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True'});
    const form = 'X_API_KEY=' + localStorage.getItem('userToken') + '&user_id=' + localStorage.getItem('userId') + '&site_type_id=' + siteType;
    return this.http.post(this.rootUrl + 'api/common/change_site', form , {headers : reqHeader});
  }

}
