import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// import { MessagingService } from './shared/messaging.service';
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  message;
  constructor(private router: Router) { /* private messagingService: MessagingService */
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
     /*  const userId = 'user001';
      this.messagingService.requestPermission(userId);
      this.messagingService.receiveMessage();
      this.message = this.messagingService.currentMessage; */
    });
  }

}
