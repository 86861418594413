import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Routing
import { AuthenticationRoutingModule } from './authentication-routing';

@NgModule({
  imports: [
    AuthenticationRoutingModule
  ],
  declarations: []
})
export class AuthenticationModule { }
