import {Component} from '@angular/core';
import {UserService} from '../.././shared/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
// import { FormsModule }   from '@angular/forms';


import {FormGroup, FormBuilder, Validators} from '@angular/forms';



@Component({
    selector: 'app-dashboard',
    templateUrl: 'otp.component.html'
})
export class OtpComponent {
    mobile = '';
    otpInput = '';
    userName: any;
    password: any;
    isGsxUser = localStorage.getItem('isGsxUser');
    gsxAuth: any;
    gsxApiInput;
    gsxAuthError = '';
    gsxApiKey = '';
    constructor(private userService: UserService, private router: Router, private fb: FormBuilder, private route: ActivatedRoute) {
      this.route
        .queryParams
        .subscribe(params => { // alert(params['user_id']);
            // Defaults to 0 if no query param provided.
            this.mobile = params['mobile'];
            // this.page = +params['serviceId'] || 0;
        });
    }

    OnSubmit() {
        this.userService.userOtpAuthentication(this.mobile, this.otpInput).subscribe((otpData: any) => {
            if (otpData.user.key) {
              localStorage.setItem('userToken', otpData.user.key);
              localStorage.setItem('userRole', otpData.user.group_id);
              localStorage.setItem('userGroup', otpData.user.user_group);
              localStorage.setItem('userId', otpData.user.user_id);
              localStorage.setItem('userName', otpData.user.user_name);
              localStorage.setItem('UserMobile', otpData.user.mobile);
              localStorage.setItem('siteType', otpData.user.site_type_id);
              localStorage.setItem('mutipleAccess', otpData.user.mutiple_site_access);
              let authKey: any;
              if (this.isGsxUser === 'true') {
                if (this.gsxApiKey === '') {
                  authKey = '';
                } else {
                  authKey = this.gsxApiKey;
                }
                this.userService.authenticateGSX(authKey).subscribe((data: any) => {
                  this.gsxAuth = data.status;
                  if (this.gsxAuth === true) {
                    if ((otpData.user.group_id === '2') || (otpData.user.group_id === '18')) {
                      this.mapCrmGSX();
                      this.mapBlueDartTrack();
                    }
                    this.router.navigate([otpData.user.default_page]);
                  } else {
                    this.gsxApiInput = true;
                    if (this.gsxApiKey !== '') {
                      this.gsxAuthError = 'Enter Valid GSX Authentication Token';
                    }
                  }
                },
                (error: HttpErrorResponse) => {
                    console.log(HttpErrorResponse);
                });
              } else {
                this.router.navigate([otpData.user.default_page]);
              }
            } else {
                alert('Invalid OTP');
                // this.router.navigate(['otp'], { queryParams: { mobile: mobile} });
            }
        },
        (error: HttpErrorResponse) => {
            alert('Invalid OTP');
        });
    }

    mapCrmGSX() {
      const ticketId = '';
      this.userService.mapCrmGsx(ticketId).subscribe((data: any) => {
        // console.log(data);
      },
      (error: HttpErrorResponse) => {
          console.log(HttpErrorResponse);
      });
    }

    mapBlueDartTrack() {
      const ticketId = '';
      this.userService.mapBlueDartTrack(ticketId).subscribe((data: any) => {
        // console.log(data);
      },
      (error: HttpErrorResponse) => {
          console.log(HttpErrorResponse);
      });
    }

}
