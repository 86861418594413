import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean {
      if (localStorage.getItem('userToken') !== null) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
  }
}

/*
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import {UserService} from '../shared/user.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  any {
      console.log(state.url);
      const statusId = state.url.match(/\d+/g);
      console.log(statusId);
        if (localStorage.getItem('userToken') != null) {
          if (statusId !== null) {
            localStorage.setItem('statusId', statusId[0]);
            this.userService.getWidgetPermission(localStorage.getItem('userRole'), statusId[0]).subscribe((data: any) => {
              if (data.status === 1) {
                  return true;
              } else {
                 localStorage.removeItem('userToken');
                 this.router.navigate(['/login']);
                 return false;
              }
          }, (error: HttpErrorResponse) => {
              alert('Invalid access attempt');
          });
          } else {
            this.userService.getMenuPermission(localStorage.getItem('userRole'), state.url).subscribe((data: any) => {
              if (data.status === 1) {
                  return true;
              } else {
                 localStorage.removeItem('userToken');
                 this.router.navigate(['/login']);
                 return false;
              }
          }, (error: HttpErrorResponse) => {
              alert('Invalid access attempt');
          });

         return true;
          }
        } else {
            this.router.navigate(['/login']);
            return false;
        }
  }
}
 */
